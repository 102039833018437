<template lang="pug">
q-page.full-height.scroll
  .q-pa-md.q-gutter-md
    c-dtable-rpi-revisions
</template>

  <style>
</style>

  <script>
export default {};
</script>
